import { createContext, useContext } from 'react';
import appAdmin from './AppAdmin';
import cdSettings from './CdSettings';
import costSettings from './CostSettings';
import changePassword from './ChangePassword';
import costDashboard from './CostDashboard';
import detail from './Detail';
import favorite from './Favorite';
import feedback from './Feedback';
import infoDetail from './InfoDetail';
import logCenter from './LogCenter';
import login from './Login';
import messageCenter from './MessageCenter';
import monitor from './Monitor';
import operationHistory from './OperationHistory';
import vehicleSupport from "./VehicleSupport";
import overview from './Overview';
import personalInfo from './PersonalInfo';
import roleAdmin from './RoleAdmin';
import tagAdmin from './TagAdmin';
import userAdmin from './UserAdmin';
import loggingStore from './Logging';
import MeshStore from './Mesh'
import aiLabStore from './AiLab'


class RootStore {
  login = login;
  overview = overview;
  infoDetail = infoDetail;
  monitor = monitor;
  detail = detail;
  logCenter = logCenter;
  favorite = favorite;
  messageCenter = messageCenter;
  operationHistory = operationHistory;
  vehicleSupport = vehicleSupport;
  appAdmin = appAdmin;
  personalInfo = personalInfo;
  changePassword = changePassword;
  tagAdmin = tagAdmin;
  feedback = feedback;
  roleAdmin = roleAdmin;
  userAdmin = userAdmin;
  cdSettings = cdSettings;
  costSettings = costSettings;
  costDashboard = costDashboard;
  loggingStore = loggingStore;
  meshStore = MeshStore;
  aiLabStore = aiLabStore;
}

const store = new RootStore();

const Context = createContext(store);

export default function useStore() {
  return useContext(Context);
}
