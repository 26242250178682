import { Result, Spin } from 'antd';
import { lazy, Suspense } from 'react';
import { Navigate } from 'react-router-dom';

const AppLayout = lazy(() => import('./pages/appLayout'));
const Overview = lazy(() => import('./pages/overview'));
const Favorite = lazy(() => import('./pages/favorite'));
const Monitor = lazy(() => import('./pages/monitor'));
const Login = lazy(() => import('./pages/login'));
const PpnLogin = lazy(() => import('./pages/ppnLogin'));
const ApplicationDetail = lazy(() => import('./pages/applicationDetail'));
const Detail = lazy(() => import('./pages/detail'));
const LogCenter = lazy(() => import('./pages/logCenter'));
const MessageCenter = lazy(() => import('./pages/messageCenter'));
const OperationHistory = lazy(() => import('./pages/operationHistory'));
const AppAdmin = lazy(() => import('./pages/appAdmin'));
const PersonalInfo = lazy(() => import('./pages/personalInfo'));
const ChangePassword = lazy(() => import('./pages/changePassword'));
const CreateApp = lazy(() => import('./pages/appAdmin/createApp'));
const TagAdmin = lazy(() => import('./pages/tagAdmin'));
const FeedbackHistory = lazy(() => import('./pages/feedbackHistory'));
const RoleAdmin = lazy(() => import('./pages/roleAdmin'));
const UserAdmin = lazy(() => import('./pages/userAdmin'));
const FeedbackCenter = lazy(() => import('./pages/feedbackCenter'));
const ChangeLog = lazy(() => import('./pages/changeLog'));
const PpnLogout = lazy(() => import('./pages/ppnLogout'));
const CdSettings = lazy(() => import('./pages/cdSettings'));
const CostSettings = lazy(() => import('./pages/costSettings'));
const CostDashboard = lazy(() => import('./pages/costDashboard'));
const AccountCost = lazy(() => import('./pages/costDashboard/accountCost'));
const Application = lazy(() => import('./pages/costDashboard/application'));
const AppCostAnalysis = lazy(() => import('./pages/costDashboard/appCostAnalysis'));
const ServiceConst = lazy(() => import('./pages/costDashboard/serviceCost'));
const CostCalculate = lazy(() => import('./pages/costCalculate'));
const Event = lazy(() => import('./pages/event'));
const Logging = lazy(() => import('./pages/logging'));
const ESLogging = lazy(() => import('./pages/logging/es'));
const SplunkLogging = lazy(() => import('./pages/logging/splunk'));
const CostAnalysis = lazy(() => import('./pages/costCalculate/costAnalysis'));
const AssessmentResult = lazy(() => import('./pages/costCalculate/assessmentResult'));
const AiLab = lazy(() => import('./pages/aiLab'));
const AiLabChat = lazy(() => import('./pages/aiLab/chat'));
const VehicleSupport = lazy(() => import('./pages/vehicleSupport'));


//避免闪屏x
const loadLazy = (children) => {
  return (
    <Suspense
      fallback={
        <div
          style={{
            textAlign: 'center',
            fontSize: '20px',
            padding: '100px 0'
          }}
        >
          <Spin />
        </div>
      }
    >
      {children}
    </Suspense>
  );
};

export const router = [
  {
    path: '/',
    element: loadLazy(<AppLayout />),
    children: [
      {
        index: true,
        element: <Navigate to="/dashboard/overview" />
      },
      {
        path: 'dashboard',
        authority: 'DASHBOARD_VIEW',
        children: [
          {
            index: true,
            element: <Navigate to="/dashboard/overview" />
          },
          {
            path: 'overview',
            authority: 'APP_OVERVIEW',
            children: [
              {
                path: '',
                element: loadLazy(<Overview />),
                from: 'overview'
              },
              {
                path: 'applicationDetail',
                element: loadLazy(<ApplicationDetail />),
                from: 'overview'
              },
              {
                path: 'detail',
                element: loadLazy(<Detail />),
                from: 'overview'
              },
              {
                path: 'event',
                element: loadLazy(<Event />),
                from: 'overview'
              },
              {
                path: 'logging',
                element: loadLazy(<Logging />),
                from: 'overview'
              },
              {
                path: 'es',
                element: loadLazy(<ESLogging />),
                from: 'overview'
              },
              {
                path: 'splunk',
                element: loadLazy(<SplunkLogging />),
                from: 'overview'
              }
            ]
          },
          {
            path: 'favorite',
            authority: 'APP_OVERVIEW',
            children: [
              {
                path: '',
                element: loadLazy(<Favorite />),
                from: 'favorite'
              },
              {
                path: 'applicationDetail',
                element: loadLazy(<ApplicationDetail />),
                from: 'favorite'
              },
              {
                path: 'detail',
                element: loadLazy(<Detail />),
                from: 'favorite'
              },
              {
                path: 'event',
                element: loadLazy(<Event />),
                from: 'favorite'
              },
              {
                path: 'logging',
                element: loadLazy(<Logging />),
                from: 'favorite'
              },
              {
                path: 'es',
                element: loadLazy(<ESLogging />),
                from: 'favorite'
              },
              {
                path: 'splunk',
                element: loadLazy(<SplunkLogging />),
                from: 'favorite'
              },
            ]
          }
        ]
      },
      {
        path: 'monitor',
        authority: 'APP_MONITROING',
        children: [
          {
            index: true,
            element: <Navigate to="/monitor/appMonitor" />
          },
          {
            path: 'appMonitor',
            element: loadLazy(<Monitor />),
            authority: 'APP_MONITORING_VIEW'
          },
          {
            path: 'logCenter',
            element: loadLazy(<LogCenter />),
            authority: 'APP_LOG_CENTER_VIEW'
          },
          {
            path: 'detail',
            element: loadLazy(<Detail />),
            from: 'appMonitor',
            authority: 'APP_MONITORING_VIEW'
          }
        ]
      },
      {
        path: 'audit',
        element: loadLazy(<OperationHistory />),
        authority: 'AUDIT'
      },
      {
        path: 'preference',
        authority: 'PREFERENCE',
        children: [
          {
            index: true,
            element: <Navigate to="/preference/tagAdmin" />
          },
          {
            path: 'tagAdmin',
            element: loadLazy(<TagAdmin />),
            authority: 'TAG_ADMIN'
          }
        ]
      },
      {
        path: 'console',
        authority: 'ADMIN_CONSOLE',
        children: [
          {
            index: true,
            element: <Navigate to="/console/appAdmin" />
          },
          {
            path: 'appAdmin',
            element: loadLazy(<AppAdmin />),
            authority: 'APP_ADMIN'
          },
          {
            path: 'createApp',
            element: loadLazy(<CreateApp />),
            from: 'appAdmin',
            authority: 'APP_ADMIN'
          },
          {
            path: 'roleAdmin',
            element: loadLazy(<RoleAdmin />),
            authority: 'ROLE_ADMIN'
          },
          {
            path: 'userAdmin',
            element: loadLazy(<UserAdmin />),
            authority: 'USER_ADMIN'
          },
          {
            path: 'feedbackCenter',
            element: loadLazy(<FeedbackCenter />),
            authority: 'FEEDBACK_CENTER'
          },
          {
            path: 'cdSettings',
            element: loadLazy(<CdSettings />),
            authority: 'CD_SETTINGS'
          },
          {
            path: 'costSettings',
            element: loadLazy(<CostSettings />),
            authority: 'CD_SETTINGS'
          }
        ]
      },
      {
        path: 'finance',
        authority: 'COST_FINANCE',
        children: [
          {
            path: 'costDashboard',
            authority: 'COST_EXP_VIEW',
            children: [
              {
                index: true,
                element: loadLazy(<CostDashboard />),
                from: 'costDashboard'
              },
              {
                path: 'accountCost',
                element: loadLazy(<AccountCost />),
                from: 'costDashboard'
              },
              {
                path: 'application',
                element: loadLazy(<Application />),
                from: 'costDashboard'
              },
              {
                path: 'appCostAnalysis',
                element: loadLazy(<AppCostAnalysis />),
                from: 'costDashboard'
              },
              {
                path: 'service',
                element: loadLazy(<ServiceConst />),
                from: 'costDashboard'
              }
            ]
          },
          {
            path: 'costCalculate',
            authority: 'COST_MODEL_VIEW',
            children: [
              {
                index: true,
                element: loadLazy(<CostCalculate />),
                from: 'costCalculate'
              },
              {
                path: 'costAnalysis',
                element: loadLazy(<CostAnalysis />),
                from: 'costCalculate'
              },
              {
                path: 'assessmentResult',
                element: loadLazy(<AssessmentResult />),
                from: 'costCalculate'
              }
            ]
          }
        ]
      },
      {
        path: 'experimental',
        children: [
          {
            path: 'aiLab',
            children: [
              {
                index: true,
                element: loadLazy(<AiLab />),
                from: 'aiLab'
              },
              {
                path: 'chat',
                element: loadLazy(<AiLabChat />),
                from: 'aiLab'
              },
            ]
          }
        ]
      },
      {
        path: 'vehicle',
        authority: 'VEHICLE_LOG',
        children: [
          {
            path: 'support',
            element: loadLazy(<VehicleSupport />),
            from: 'vehicle'
          }
        ]
      },
      {
        path: 'personalInfo',
        element: loadLazy(<PersonalInfo />)
      },
      {
        path: 'changePassword',
        element: loadLazy(<ChangePassword />)
      },
      {
        path: 'changeLog',
        element: loadLazy(<ChangeLog />)
      },
      {
        path: 'messageCenter',
        element: loadLazy(<MessageCenter />)
      },
      {
        path: 'feedbackHistory',
        element: loadLazy(<FeedbackHistory />)
      },
      {
        path: '*',
        element: (
          <Result
            status="404"
            title="404"
            subTitle="Sorry, the page you visited does not exist."
            // extra={<Button type="primary">Back Home</Button>}
          />
        ),
        authority: 'page404'
      }
    ]
  },
  {
    path: 'ppnLogin',
    element: loadLazy(<PpnLogin />)
  },
  {
    path: 'ppnLogout',
    element: loadLazy(<PpnLogout />)
  },
  {
    path: 'login',
    element: loadLazy(<Login />)
  },
  {
    path: '*',
    // element: <Navigate to="/" />
    element: (
      <Result
        status="404"
        title="404"
        subTitle="Sorry, the page you visited does not exist."
        // extra={<Button type="primary">Back Home</Button>}
      />
    ),
    authority: 'page404'
  }
];
